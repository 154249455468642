import { GET_CART } from '../cart/queries'
import { GET_STATUS } from '../global/queries';
import { ticketCount } from '../../utils/helpers';

export const resolvers = {
  Query: {
    async cart(_, __, { cache }) {
      const { cart } = cache.readQuery({ query: GET_CART });
      return {
        __typename: "Cart",
        ...cart.items,
        subtotal: 0
      }
    },
    async status(_, __, { cache }) {
      const { status } = cache.readQuery({ query: GET_STATUS });
      return {
        __typename: "Status",
        ...status
      }
    },
    async subtotal() {
      return 0
    }
  },
  // Cart: {
  //   async items() {
  //     return []
  //   },
  //   async subtotal() {
  //     return 0
  //   }
  // },
  Cart: {
    subtotal(_, __, { cache }) {
      const { cart } = cache.readQuery({ query: GET_CART });

      const subtotal = cart.items.reduce((subtotal, item) => {
        return subtotal + item.tickets.reduce((total, ticket) => {
          if (ticket.quantity) {
            return total + (ticket.quantity * ticket.cost)
          }
          return total + 0
        }, 0)
      }, 0)

      localStorage.setItem('cart', JSON.stringify({ ...cart, subtotal }))

      return subtotal
    },
  },
  Mutation: {
    async addItemToCart(_, { item }, { cache }) {
      const { cart } = cache.readQuery({ query: GET_CART });
      const index = cart.items.findIndex(ci => item.id === ci.id);
      
      let updatedCart;

      if (index < 0) {
        updatedCart = {
            ...cart,
            items: [...cart.items, {
            __typename: "CartItem",
            ...item,
            subtotal: item.tickets.reduce((total, ticket) => {  
              if (ticket.quantity) {
                return total + (ticket.quantity * ticket.cost)
              }
              return total + 0
            }, 0)
          }]
        }
      } else {
        const existingItem = cart.items[index]
        const updatedItem = {
          ...existingItem,
          __typename: "CartItem",
          tickets: existingItem.tickets.map(({ quantity, ...rest }, i) => {
            return {
              ...rest,
              __typename: "TicketQuantity",
              quantity: quantity + item.tickets[i].quantity
            }
          })
        }
        const updatedItemWithSubtotal = {
          ...updatedItem,
          subtotal: updatedItem.tickets.reduce((total, ticket) => {  
            if (ticket.quantity) {
              return total + (ticket.quantity * ticket.cost)
            }
            return total + 0
          }, 0)
        }
        const items = JSON.parse(JSON.stringify(cart.items))
        items.splice(index, 1, updatedItemWithSubtotal)
        updatedCart = {
          ...cart,
          items
        }
      }      
      cache.writeData({
        data: { 
          cart: updatedCart
        }
      });

      localStorage.setItem('cart', JSON.stringify(updatedCart))
      
      return updatedCart;
    },
    async updateCartItem(_, { item, ticket, quantity }, { cache }) {

      const { cart } = await cache.readQuery({ query: GET_CART });
      const updatedCart = JSON.parse(JSON.stringify(cart))

      console.log({ item, ticket, quantity });
      
      
      let updateItem = true

      if ((quantity === 0) && (ticketCount(updatedCart.items[item].tickets) - 1) <= 0) {
        updateItem = confirm('Are you sure you want to delete these tickets?')
      }

      if (updateItem) {
          
        updatedCart.items[item].tickets[ticket].quantity = quantity || 0

        if (ticketCount(updatedCart.items[item].tickets) === 0) {
          updatedCart.items.splice(item, 1)
          cache.writeData({ 
            data: {
              cart: updatedCart,
              status: {
                __typename: "Status",
                code: 'SUCCESS',
                message: 'Tickets successfully removed from cart.'
              }
            }
          });
        } else {
          updatedCart.items[item].subtotal = updatedCart.items[item].tickets.reduce((total, ticket) => {  
            if (ticket.quantity) {
              return total + (ticket.quantity * ticket.cost)
            }
            return total + 0
          }, 0)
          cache.writeData({ data: { cart: updatedCart } });
        }
        
        localStorage.setItem('cart', JSON.stringify(updatedCart))

        return updatedCart
      }
    },
    deleteCartItem(_, { item }, { cache }) {
      const { cart } = cache.readQuery({ query: GET_CART });

      const updatedCart = cart
      updatedCart.items.splice(item, 1)
      
      cache.writeData({
        data: { 
          cart: updatedCart
        }
      });

      localStorage.setItem('cart', JSON.stringify(updatedCart))

      return updatedCart
    },
    deleteManyCartItems(_, { items }, { cache }) {
      const { cart } = cache.readQuery({ query: GET_CART });

      const updatedCart = cart

      items.map(item => {
        const index = cart.items.findIndex(ci => item === ci.id);
        if (index !== -1) {
          updatedCart.items.splice(index, 1)
        }
      })
      
      cache.writeData({
        data: { 
          cart: updatedCart
        }
      });

      localStorage.setItem('cart', JSON.stringify(updatedCart))

      return updatedCart
    },
    emptyCart(_, __, { cache }) {
      const { cart } = cache.readQuery({ query: GET_CART });

      const updatedCart = cart
      cart.items = []
      cart.subtotal = 0
      
      cache.writeData({
        data: { 
          cart: updatedCart
        }
      });

      localStorage.setItem('cart', JSON.stringify(updatedCart))

      return updatedCart

    },
    async setStatus(_, { code, message}, { cache }) {
      await cache.writeData({
        data: {
          status: {
            __typename: "Status",
            code,
            message
          }
        }
      });
      return {
        __typename: "Status",
        code,
        message
      }
    }
  }
};