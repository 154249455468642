import { useEffect, useRef } from "react";
import { client } from '../apollo/client'
import { SET_STATUS, GET_STATUS } from "./queries";

// const INIT_STATUS = {
//   code: null,
//   message: null
// }

// function useStatus() {
//   const [status, setStatus] = useState(INIT_STATUS)

//   const resetStatus = () => {
//     setStatus(INIT_STATUS)
//   }

//   return {
//     ...status,
//     setStatus,
//     resetStatus
//   };
// }

function useIsMounted() {
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted; // returning "isMounted.current" wouldn't work because we would return unmutable primitive
}

function useStatus() {

  const setError = (message) => {
    client.mutate({
      mutation: SET_STATUS,
      variables: {
        __typename: "Status",
        code: 'ERROR',
        message: message
      },
      refetchQueries: [{
        query: GET_STATUS
      }]
    })
  }

  const setSuccess = (message) => {
    client.mutate({
      mutation: SET_STATUS,
      variables: {
        __typename: "Status",
        code: 'SUCCESS',
        message: message
      },
      refetchQueries: [{
        query: GET_STATUS
      }]
    })
  }

  return {
    setError,
    setSuccess
  }
}

export {
  useStatus,
  useIsMounted
}