import gql from 'graphql-tag'

const GLOBAL_ERROR = gql`
  query GetGlobalErrors {
    globalError @client
  }
`

const GET_STATUS = gql`
  query GetStatus {
    status @client {
      __typename
      code
      message
    }
  }
`

const SET_STATUS = gql`
  mutation SetStatus($code: String!, $message: String!) {
    setStatus(code: $code, message: $message) @client {
      __typename
      code
      message
    }
  }
`

export {
  GLOBAL_ERROR,
  GET_STATUS,
  SET_STATUS
}