import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import { from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import fetch from 'node-fetch';
import { resolvers } from './resolvers';
//import { typeDefs } from './schema';

const cache = new InMemoryCache();

const link = from([
  onError(({ networkError }) => {
    if (networkError) {
      cache.writeData({
        data: {
          status: {
            __typename: "Status",
            code: 'ERROR',
            message: `There has been a problem connecting to the server. Trying again...`
          }
        }
      });
    };
  }),
  new RetryLink(),
  new HttpLink({
    uri: process.env.GATSBY_API_URL,
    fetch
  })
]);

const client = new ApolloClient({
  cache,
  link,
  resolvers,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
  //typeDefs
})

const INIT_CART = {
  __typename: "Cart",
  items: [],
  subtotal: 0
}

if (typeof window !== `undefined`) {
  cache.writeData({
    data: {
      cart: JSON.parse(window.localStorage.getItem('cart')) || INIT_CART,
      status: {
        __typename: "Status",
        code: null,
        message: null
      }
    },
  });
} else {
  cache.writeData({
    data: {
      cart: INIT_CART,
      status: {
        __typename: "Status",
        code: null,
        message: null
      }
    },
  });
}

export { client }