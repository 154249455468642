import gql from 'graphql-tag';

const INIT_ORDER_MUTATION = gql`
  mutation InitOrderMutation(
    $amount: Int!
    $intent: String
  ) {
    initOrder(amount: $amount, intent: $intent) {
      id
      clientSecret
    }
  }
`

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrderMutation(
    $email: String!,
    $name: String!,
    $phone: String!,
    $bookings: [OrderBookingsInput!]!,
    $amount: Int!,
    $intent: String!
  ) {
    createOrder(
      email: $email,
      name: $name,
      phone: $phone,
      bookings: $bookings,
      amount: $amount,
      intent: $intent
    ) {
      id
    }
  }
`

const GET_CART_JOURNEYS = gql`
  query GetCartJourneys(
    $ids: [ID!]!
  ) {
    journeys(
      where: {
        id_in: $ids
      }
    ) {
      edges {
        node {
          id
          date
          trip {
            id
            title
            image
          }
          seatsAvailable
          seatsRemaining
        }
      }
    }
  }
`

const GET_CART = gql`
  query GetCart {
    cart @client {
      items @client {
        id
        title
        date
        tickets @client {
          title
          cost
          quantity
        }
        subtotal @client
      }
      subtotal @client
    }
    status @client {
      code
      message
    }
  }
`

const ADD_ITEM_TO_CART = gql`
  mutation AddToCart($item: CartItem!) {
    addItemToCart(item: $item) @client
  }
`

const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem(
    $item: Int!
    $ticket: Int!
    $quantity: Int!
  ) {
    updateCartItem(item: $item, ticket: $ticket, quantity: $quantity) @client
  }
`

const DELETE_CART_ITEM = gql`
  mutation DeleteCartItem(
    $item: Int!
  ) {
    deleteCartItem(item: $item) @client
  }
`

const DELETE_MANY_CART_ITEMS = gql`
  mutation DeleteManyCartItems(
    $items: [ID!]!
  ) {
    deleteManyCartItems(items: $items) @client
  }
`

const EMPTY_CART = gql`
  mutation EmptyCart {
    emptyCart @client
  }
`

export {
  INIT_ORDER_MUTATION,
  CREATE_ORDER_MUTATION,
  GET_CART_JOURNEYS,
  GET_CART,
  ADD_ITEM_TO_CART,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEM,
  DELETE_MANY_CART_ITEMS,
  EMPTY_CART
}