import moment from 'moment'
import { DELETE_MANY_CART_ITEMS, GET_CART } from '../components/cart/queries';
import { useStatus } from '../components/global/customHooks';

const formatDate = (date, time = false) => {
  if (time) {
    return moment(date).format('Do MMMM YYYY, HH:mm')
  }
  return moment(date).format('Do MMMM YYYY')
}

const ticketCount = (tickets) => {
  return tickets.reduce((count, t) => {
    if (t.quantity) {
      return count + t.quantity
    }
    return count + 0
  }, 0)
}

const hasExpiredCartItems = (cart, client) => {
  const { setError } = useStatus()

  const expired = cart.items.filter(item => { 
    return moment(item.date).isSameOrBefore(moment())
  }).map(item => item.id)
  if (expired.length > 0) {
    const { error } = client.mutate({
      mutation: DELETE_MANY_CART_ITEMS,
      variables: {
        items: expired
      },
      refetchQueries: [{
        query: GET_CART
      }]
    })
    if (!error) {
      setError('Expired items have been removed from your cart.')
    }
    return true
  }
  return false
}

export {
  formatDate,
  ticketCount,
  hasExpiredCartItems
}