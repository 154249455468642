import React from 'react'
import { Helmet } from 'react-helmet'
import { client } from './src/components/apollo/client'
import { ApolloProvider } from 'react-apollo';
import './src/css/global.css'
import { SET_STATUS } from './src/components/global/queries';

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <Helmet>
      <meta property="fb:app_id" content="456092181616454" />
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '387736591843713');
          fbq('track', 'PageView');
        `}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=387736591843713&ev=PageView&noscript=1" />`}
      </noscript>
    </Helmet>
    {element}
  </ApolloProvider>
);

export const onRouteUpdate = () => {
  client.mutate({
    mutation: SET_STATUS,
    variables: {
      __typename: "Status",
      code: null,
      message: null
    }
  })
}